import https from "./https";
import store from "@/store";

const point = {
  async getRewardsCard(id) {
    const organization = store.state.liffGeneral.orgCode
    let { data } = await https.get(`${organization}/liff/reward-cards/${id}`)
    return data.data
  },

  async listGifts(id, page = 1) {
    const organization = store.state.liffGeneral.orgCode
    let { data } = await https.get(`${organization}/liff/reward-cards/${id}/gifts?page=${page}`)
    return data
  },

  async redeemGift(id, giftId) {
    const organization = store.state.liffGeneral.orgCode
    await https.post(`${organization}/liff/reward-cards/${id}/redeem`, {
      gift_id: giftId,
    })
  },

  async listProductsOfShop(id, branchId) {
    const organization = store.state.liffGeneral.orgCode
    let { data } = await https.get(`${organization}/liff/reward-cards/${id}/branches/${branchId}/products`)
    return data
  },
}

export default point
